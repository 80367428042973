<script lang="ts">
  let open = false;

  const toggle = () => {
    open = !open;
    document.body.classList.toggle("noscroll", open)
  };
</script>

<button class="md:hidden p-2 z-20 relative" on:click={toggle}>
  {#if !open}
    <slot name="open-icon" />
    <span class="sr-only">Show Navigation</span>
  {/if}
  {#if open}
    <slot name="close-icon" />
    <span class="sr-only">Hide Navigation</span>
  {/if}
</button>

<div
  class="{open ? "grid" : "hidden"} md:hidden fixed top-0 left-0 h-screen w-screen overflow-clip">
  <div class="bg-neutral w-full h-full col-start-1 row-start-1"></div>
  <div class="col-start-1 row-start-1 p-10 pt-20">
    <slot />
  </div>
</div>
